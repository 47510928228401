<template>
  <div class="ordinals">
    <div class="text-ordinals">
      Bitcoin is the store of value.
    </div>
    <div class="text-ordinals">
      Ordinals are the store of culture.
    </div>
    <div class="text-ordinals">
      BAVO makes cultures on Bitcoin.
    </div>
    <div class="ordinals-footer"></div>
  </div>
</template>

<script>
export default {
  name: 'Ordinals',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.ordinals {
  position: relative;
  padding: 272px 180px 150px;
  color: #000;
  font-size: 34.64px;
  font-weight: 400;
  overflow: hidden;
  
}
.text-ordinals {
  margin-bottom: 130px;
}
.ordinals-footer {
  position: absolute;
  bottom: 0;
  left: 323px;
  width: 100%;
  height: 100px;
  background-image: url("~@/assets/images/footer-img.png");
  background-position: bottom 0px left 0px;
  background-size: 500px 91px;
  background-repeat: repeat no-repeat;
}
</style>
